import React from "react";
import SimpleForm from "./SimpleForm";

const CustomForm = () => (
  <div className="text-center">
    <SimpleForm />
  </div>
);

export default CustomForm;
